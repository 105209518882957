



























































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { requiredIf, sameAs } from "vuelidate/lib/validators";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import { validationMixin } from "vuelidate";
import { mixins } from "vue-class-component";
import User from "@/models/User.model";
import { AUTH_STORE_NAME } from "@/store/auth.store";
import { namespace } from "vuex-class";
import IconTooltipComponent from "@/components/IconTooltip.component.vue";
import { PaymentMethod } from "@/enum/PaymentMethod";
import { UserType } from "@/enum/UserType.enum";
import BillingDetails from "@/models/BillingDetails";

const AuthStore = namespace(AUTH_STORE_NAME);

@Component({
  components: {
    IconTooltip: IconTooltipComponent
  },
  mixins: [validationMixin],
  validations: {
    data: {
      owner: {
        required: requiredIf((vm) => {
          return vm.userType === UserType.COMPANY && vm.usesBankTransfer === false;
        })
      },
      iban: {
        required: requiredIf((vm) => {
          return vm.usesBankTransfer === false;
        })
      },
      bic: {
        required: requiredIf((vm) => {
          return vm.usesBankTransfer === false;
        })
      },
    }
  }
})
export default class RegisterPaymentComponent extends mixins(
  ErrorMessageHandlerMixin
) {
  @Prop({ default: null })
  private user!: User;

  private paymentMethod: PaymentMethod = PaymentMethod.debit;

  /**
   * SEPA Mandate Terms Accepted
   */
  hasSepaMandateTermsAccepted: boolean = false

  /**
   * Entered Data Object
   * @private
   */
  private data = {
    usesBankTransfer: false,
    userType: UserType.CUSTOMER,
    owner: "",
    iban: "",
    bic: "",
  };

  onSepaMandateTermsChanged(value: boolean) {
    this.hasSepaMandateTermsAccepted = value;
  }

  private get isSepaMandateTermsAccepted() {
    return this.hasSepaMandateTermsAccepted || this.data.usesBankTransfer;
  }

  private get isDebit() {
    return this.paymentMethod == PaymentMethod.debit;
  }

  created() {
    this.data.userType = this.user.type || UserType.CUSTOMER;
  }

  @Watch("user", { immediate: true, deep: true })
  onUserChanged() {
    this.data.userType = this.user.type || UserType.CUSTOMER;
  }

  private switchPayment() {
    if (
      this.user.type === UserType.CUSTOMER ||
      this.user.billingDetails === undefined
    )
      return;
    if (this.paymentMethod == PaymentMethod.debit) {
      this.paymentMethod = PaymentMethod.transfer;
      this.data.usesBankTransfer = true;
    } else if (this.paymentMethod == PaymentMethod.transfer) {
      this.paymentMethod = PaymentMethod.debit;
      this.data.usesBankTransfer = false;
    }

    // Trigger validation for iban and bic
    this.$v.$reset();
  }

  private onRegister() {
    this.user.billingDetails = this.paymentMethod == PaymentMethod.transfer
      ? BillingDetails.parseFromObject({ usesBankTransfer: true })
      : BillingDetails.parseFromObject(this.data);

    this.$emit("finish", this.user);
  }

  private get checkUserType(): boolean {
    return this.user.type === UserType.CUSTOMER;
  }

  private get isValid() {
    console.log(this.$v);
    return !this.$v.$invalid;
  }

  private validateIban(): boolean {
    if (this.data.iban.length < 27)
      this.data.bic = "";

    // Call iban-to-bic library that is imported inside the index.html as a javascript file due to missing
    // typescript support
    let bic = ibanToBic.ibanToBic(this.data.iban);

    if (bic) {
      this.data.bic = bic;
    }

    return this.triggerValidation("data.iban");
  }
}
